<template>
  <v-container class="payment-type payment-type-redirect">
    <i18n
      :path="`payment.paymentTypeList.tooltip.${paymentType.paymentTypeId}`"
      tag="span"
      class="tooltip-payment-methods"
    />
    <PrebilledAmount
      class="mt-4"
      :mode="mode"
      :paymentTypeId="paymentType.paymentTypeId"
      :order-id="orderId"
    />
    <PaymentAuthList
      @selectPaymentAuth="selectPaymentAuth"
      @addNewCard="addNewCard"
      @toggleRememberCard="toggleRememberCard"
      :paymentTypeId="paymentType.paymentTypeId"
    />
    <v-checkbox v-model="rememberNewCard" label="Memorizza carta" />
    <ResponseMessage :response="response" />
    <div class="new-card d-flex justify-start">
      <!-- <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" dark v-bind="attrs" v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>
          La tua carta di credito verrà memorizzata sui server sicuri di Banca
          Intesa <br />
          e potrai utilizzarla per i tuoi acquisti futuri.
        </span>
      </v-tooltip> -->
    </div>
    <v-row class="d-flex justify-end mt-5">
      <v-col cols="6" md="6" class="d-flex" v-if="mode != 'order'">
        <v-btn
          outlined
          color="primary"
          depressed
          v-on:click="goBack"
          :disabled="loading"
          class="bg-white"
          >{{ $t("common.back") }}
        </v-btn>
      </v-col>
      <v-col cols="6" md="6" class="d-flex justify-end">
        <v-btn
          color="primary"
          class="ml-2 gateway-btn"
          v-on:click="buy"
          depressed
          :disabled="paymentDisabled"
        >
          {{ $t("payment.paymentTypeList.orderConfirm") }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="caption mt-5">
      {{ $t("payment.paymentTypeList.monetawebCaption") }}
    </div>
  </v-container>
</template>
<script>
import PrebilledAmount from "./PrebilledAmount";
import PaymentAuthList from "./PaymentAuthList";
import ResponseMessage from "@/components/ResponseMessage";
import GatewayUtilsService from "./gatewayUtilsService";
import OrderService from "~/service/orderService";
import gateway from "~/mixins/gateway";

import { mapActions } from "vuex";

var vm;

export default {
  name: "PaymentTypeMonetaweb",
  mixins: [gateway],
  props: { paymentDisabled: { type: Boolean, default: false } },
  data() {
    return {
      loading: false,
      error: null,
      selectedPaymentAuthId: null,
      rememberNewCard: false,
      response: {}
    };
  },
  components: { PrebilledAmount, PaymentAuthList, ResponseMessage },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart"
    }),
    async buy() {
      vm.loading = true;
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        if (vm.mode == "cart") {
          let cart = await vm.buyCart({
            paymentTypeId: this.paymentType.paymentTypeId,
            userPaymenthAuthId: this.selectedPaymentAuthId,
            paymentData: paymentData
          });
          if (cart) {
            vm.payResponseOk(cart);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            this.paymentType.paymentTypeId,
            this.selectedPaymentAuthId,
            paymentData
          );
          if (response) {
            vm.payResponseOk(response);
          } else {
            this.$router.push("/cart/payment-error");
          }
        } else if (vm.mode == "duedate") {
          //           PaymentDueService.duedatePay(vm.paymentDueDateIds, vm.paymentTypeId, paymentAuthId, paymentData).then(
          //   payResponseOk,
          //   payResponseKo
          // );
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    async addNewCard() {
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        let cart = await vm.buyCart({
          paymentTypeId: this.paymentType.paymentTypeId,
          paymentData: paymentData
        });
        if (cart) {
          vm.payResponseOk(cart);
        } else {
          this.$router.push("/payment-error");
        }
      } catch (err) {
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    },
    payResponseOk(cart) {
      let orderId = null;

      global.EventBus.$emit("pay-response-ok", cart);
      if (cart && cart.paymentUrl) {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        vm.doRedirect(cart.paymentUrl, cart.paymentParameters, "get", orderId);
        this.loading = false;
      } else {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        this.$router.push("/cart/payment-completed/" + orderId);
        this.loading = false;
      }
    },
    payResponseKo(data) {
      this.loading = false;
      GatewayUtilsService.handlePayResponseKo(data, this.orderId);
    },
    doRedirect(paymentUrl, paymentParameters, method, orderId) {
      GatewayUtilsService.payment(
        paymentUrl,
        paymentParameters,
        method,
        orderId
      );
    },
    goBack() {
      this.$router.push("/checkout");
    },
    selectPaymentAuth(paymentAuthId) {
      this.selectedPaymentAuthId = paymentAuthId;
    },
    toggleRememberCard(value) {
      this.rememberNewCard = value;
    }
  },
  created() {
    vm = this;
  }
};
</script>
